:root {
  /* --line-height-h1: 2.75rem;
  --line-height-h2: 2.5rem;
  --line-height-h3: 2.25rem;
  --line-height-h4: 2rem;
  --line-height-h5: 1.75rem;
  --line-height-h6: 1.5rem;
  --line-height-p: 1.5rem; */

  --font-family-primary: 'the-seasons';
  --font-family-secondary: 'lato', Lato;

  /* --size-3xs: clamp(0.64rem, calc(0.58rem + 0.06vw), 0.76rem);
  --size-2xs: clamp(0.7rem, calc(0.67rem + 0.12vw), 0.81rem);
  --size-xs: clamp(0.76rem, calc(0.75rem + 0.06vw), 0.85rem);
  --size-s: clamp(0.88rem, calc(0.83rem + 0.21vw), 1.17rem);
  --size-m: clamp(1.01rem, calc(0.92rem + 0.43vw), 1.61rem);
  --size-l: clamp(1.16rem, calc(1rem + 0.76vw), 2.22rem);
  --size-xl: clamp(1.33rem, calc(1.08rem + 1.24vw), 3.07rem);
  --size-2xl: clamp(1.53rem, calc(1.14rem + 1.93vw), 4.23rem);
  --size-3xl: clamp(1.76rem, calc(1.18rem + 2.91vw), 5.84rem);
  --size-4xl: clamp(2.02rem, calc(1.16rem + 4.31vw), 8.06rem);
  --size-5xl: clamp(2.33rem, calc(1.07rem + 6.28vw), 11.12rem); */

  /* FLUID TYPOGRAPHY */

  /* Size -3: 11.2373px → 9.216px */
  --size-2xs: clamp(0.576rem, 0.7444rem + -0.2106vw, 0.7023rem);
  --line-height-2xs: 1.3;
  /* For small text like captions */

  /* Size -2: 12.642px → 11.52px */
  --size-xs: clamp(0.72rem, 0.8135rem + -0.1169vw, 0.7901rem);
  --line-height-xs: 1.35;
  /* For small text */

  /* Size -1: 14.2222px → 14.4px */
  --size-s: clamp(0.8889rem, 0.8852rem + 0.0185vw, 0.9rem);
  --line-height-s: 1.4;
  /* For body text or small headings */

  /* Size 0: 16px → 18px */
  --size-m: clamp(1rem, 0.9583rem + 0.2083vw, 1.125rem);
  --line-height-m: 1.5;
  /* For body text or medium-sized headings */

  /* Size 1: 18px → 22.5px */
  --size-l: calc(clamp(1.125rem, 1.0313rem + 0.4688vw, 1.4063rem) - 2.5px);
  --line-height-l: 1.5;
  /* For medium-sized headings */

  /* Size 2: 20.25px → 28.125px */
  --size-xl: calc(clamp(1.2656rem, 1.1016rem + 0.8203vw, 1.7578rem) - 3px);
  --line-height-xl: 1.3;
  /* For large headings */

  /* Size 3: 22.7813px → 35.1563px */
  --size-2xl: calc(clamp(1.4238rem, 1.166rem + 1.2891vw, 2.1973rem) - 3.5px);
  --line-height-2xl: 1.25;
  /* For large headings */

  /* Size 4: 25.6289px → 43.9453px */
  --size-3xl: calc(clamp(1.6018rem, 1.2202rem + 1.908vw, 2.7466rem) - 4px);
  --line-height-3xl: 1.25;
  /* For very large headings */

  /* Size 5: 28.8325px → 54.9316px */
  --size-4xl: calc(clamp(1.802rem, 1.2583rem + 2.7187vw, 3.4332rem) - 4.5px);
  --line-height-4xl: 1.2;
  /* For extra large headings */

  /* FLUID SPACING */

  /* Space 3xs: 4px → 5px */
  --space-3xs: clamp(0.25rem, 0.2292rem + 0.1042vw, 0.3125rem);
  /* Space 2xs: 8px → 9px */
  --space-2xs: clamp(0.5rem, 0.4792rem + 0.1042vw, 0.5625rem);
  /* Space xs: 12px → 14px */
  --space-xs: clamp(0.75rem, 0.7083rem + 0.2083vw, 0.875rem);
  /* Space s: 16px → 18px */
  --space-s: clamp(1rem, 0.9583rem + 0.2083vw, 1.125rem);
  /* Space m: 24px → 27px */
  --space-m: clamp(1.5rem, 1.4375rem + 0.3125vw, 1.6875rem);
  /* Space l: 32px → 36px */
  --space-l: clamp(2rem, 1.9167rem + 0.4167vw, 2.25rem);
  /* Space xl: 48px → 54px */
  --space-xl: clamp(3rem, 2.875rem + 0.625vw, 3.375rem);
  /* Space 2xl: 64px → 72px */
  --space-2xl: clamp(4rem, 3.8333rem + 0.8333vw, 4.5rem);
  /* Space 3xl: 96px → 108px */
  --space-3xl: clamp(6rem, 5.75rem + 1.25vw, 6.75rem);

  --box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.15);
}

body {
  margin: 0;
  font-family: var(--font-family-secondary);
  font-size: var(--size-m);
  color: #374151;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #aec7d7;
    padding-right: 10px;
    border-radius: 5px;
    padding-right: 20px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #255573;
  }

  * {
    scrollbar-color: #aec7d7 #f0f0f0;
    scrollbar-width: thin;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

h1 {
  font-family: var(--font-family-primary);
  font-size: var(--size-3xl);
  line-height: var(--line-height-3xl);
  font-weight: 600;
}

h2 {
  font-family: var(--font-family-primary);
  font-size: var(--size-2xl);
  line-height: var(--line-height-2xl);
  font-weight: 600;
}

h3 {
  font-family: var(--font-family-secondary);
  font-size: var(--size-xl);
  line-height: var(--line-height-xl);
  font-weight: 600;
}

h4 {
  font-family: var(--font-family-secondary);
  font-size: var(--size-l);
  line-height: var(--line-height-l);
  font-weight: 600;
}

h5 {
  font-family: var(--font-family-secondary);
  font-size: var(--size-m);
  line-height: var(--line-height-m);
  font-weight: 600;
}

h6 {
  font-family: var(--font-family-secondary);
  font-size: var(--size-s);
  line-height: var(--line-height-s);
  font-weight: 600;
}

p,
li {
  font-family: var(--font-family-secondary);
  font-size: var(--size-m);
  line-height: var(--line-height-m);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    color: black;
    /* print-color-adjust: exact; */
    /* -webkit-print-color-adjust: exact; */
  }
}

@media print {
  .pagebreak {
    margin-top: 2rem;
    display: block;
    break-before: page;
  }

  .printdishcateringsvg {
    width: 3in;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.singleLineImageContainer {
  display: flex;
  width: 100%;
  background: #fff;
  overflow: scroll;
  margin-top: 24.5px;
  padding-left: 0.5rem;
  padding-bottom: 1rem;
}

.image {
  background-color: #fff;
  /* border: 1px solid #47728c; */
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.15);
  margin: 10px;
  padding: 12px;
  width: 300px;
  height: 275px;
}

.featured-menu-arrows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.explore-full-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  cursor: pointer;
}

@media (min-width: 0px) and (max-width: 440px) {
  .featured-menu-arrows {
    display: none;
  }

  .explore-full-menu {
    justify-content: flex-start;
  }

  .singleLineImageContainer::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .singleLineImageContainer {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.singleLineImageContainer {
  overflow-y: hidden;
}
